import Vue from 'vue'
import App from './App.vue'
import Axios from 'axios'
import VueAxios from 'vue-axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import router from './router'
import store from './store'
import api from './config/api'

 import 'font-awesome/css/font-awesome.min.css';


// vue-quill-edit

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import gloabl from '@/style/global.css'

Vue.config.productionTip = false

Vue.use(VueAxios, Axios);
Vue.use(ElementUI);
Vue.use(gloabl);

router.beforeEach((to, from, next) => {

	let token1 = localStorage.getItem('token') || '';

    //配置接口信息
    // Axios.defaults.baseURL = 'http://www.地址.com:8360/admin/';
    Axios.defaults.baseURL = api.rootUrl;
    Axios.defaults.headers.common['token'] = token1;
    Axios.interceptors.request.use(function (config) {
      let token = localStorage.getItem('token') || '';
      // 通过拦截request请求,主动为 请求头,追加新属性 Authorization,等于 token 值
      config.headers["Authorization"] ='Bearer ' + token;//添加token
      return config;
      }, function (error) {
        // 对请求错误做些什么
        return Promise.reject(error)
      })

      //http response 拦截器
      Axios.interceptors.response.use(function (res) {
        return res;
      }, function (error) {
        // 对请求错误做些什么
        console.log("ssss error is called !");
        if(error.response.status == 401)
        {
          next({
            path: '/login'
          });
        }
        return Promise.reject(error)
      })

	if (!token1 && to.name !== 'login') {
    console.log(" token is null ")
		next({
			path: '/login'
		})
	} else {
		next()
	}
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
