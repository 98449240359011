//import Home from '@/views/Home'
//import Login from '@/views/Login'
//import Reg from '@/views/Reg'
/*
     component:()=>import("@/views/Home")//这种模式是采用动态加载页面的组建的方式，在需要的时候进行资源加载
*/
var config={
    mode:"history",
    routes:[
        {
            path: '/dashboard',
            name: 'dashboard',
            component: ()=>import('@/components/DashboardPage'),//懒加载，需要在加载页面内容，优化
            children: [
                {
                    path: 'welcome',
                    name: 'welcome',
                    component: ()=>import('@/components/WelcomePage')
                },
                {
                    path: 'home_gallery',
                    name: 'home_gallery',
                    component: ()=>import('@/components/Home/HomeGallerys')
                },
                {
                    path: 'add_gallery',
                    name: 'add_gallery',
                    component: ()=>import('@/components/Home/GalleryAddPage')
                },
                {
                    path: 'home_article',
                    name: 'home_article',
                    component: ()=>import('@/components/Home/HomeArticles')
                },
                {
                    path: 'add_article',
                    name: 'add_article',
                    component: ()=>import('@/components/Home/ArticleAddPage')
                },
                {
                    path: 'home_activity_mode',
                    name: 'home_activity_mode',
                    component: ()=>import('@/components/Home/HomeActivity')
                },
                {
                    path: 'add_activity',
                    name: 'add_activity',
                    component: ()=>import('@/components/Home/ActivityAddPage')
                },
                {
                    path: 'add_goods_activity',
                    name: 'add_goods_activity',
                    component: ()=>import('@/components/Home/ActivityGoodsAddPage')
                },
                {
                    path: 'home_ads',
                    name: 'home_ads',
                    component: ()=>import('@/components/Home/HomeAds')
                },
                {
                    path: 'add_home_ads',
                    name: 'add_home_ads',
                    component: ()=>import('@/components/Home/AdsAddPage')
                },
                
                {
                    path: 'goods',
                    name: 'goods',
                    component: ()=>import('@/components/Goods/GoodsPage')
                },
                {
                    path: 'goods/add',
                    name: 'goods_add',
                    component: ()=>import('@/components/Goods/GoodsAddPage')
                },
                {
                    path: 'discount',
                    name: 'discount',
                    component: ()=>import('@/components/Discount/Discounts')
                },
                {
                    path: 'discount_add',
                    name: 'discount_add',
                    component: ()=>import('@/components/Discount/DiscountAddPage')
                },
                {
                    path: 'nature',
                    name: 'nature',
                    component: ()=>import('@/components/Nature/NaturePage')
                },
                {
                    path: 'specification/detail',
                    name: 'specification_detail',
                    component: ()=>import('@/components/Specification/SpecificationAddPage')
                },
                {
                    path: 'video',
                    name: 'video',
                    component: ()=>import('@/components/Video/VideoPage')
                },
                {
                    path: 'video/add',
                    name: 'video_add',
                    component: ()=>import('@/components/Video/VideoAddPage')
                },
                {
                    path: 'video_cate',
                    name: 'video_cate',
                    component: ()=>import('@/components/Video/VideoCatePage')
                },
                {
                    path: 'video/addcate',
                    name: 'video_addcate',
                    component: ()=>import('@/components/Video/VideoCateAddPage')
                },


                {
                    path: 'category',
                    name: 'category',
                    component: ()=>import('@/components/Category/CategoryPage')
                },
                {
                    path: 'category/add',
                    name: 'category_add',
                    component: ()=>import('@/components/Category/CategoryAddPage')
                },
                {
                    path: 'order',
                    name: 'order',
                    component: ()=>import('@/components/Order/OrderPage')
                },
                {
                    path: 'order/detail',
                    name: 'order_detail',
                    component: ()=>import('@/components/Order/OrderDetailPage')
                },
                {
                    path: 'user',
                    name: 'user',
                    component: ()=>import('@/components/User/UserPage')
                },
                {
                    path: 'user/add',
                    name: 'user_add',
                    component: ()=>import('@/components/User/UserAddPage')
                },
                {
                    path: 'shipper',
                    name: 'shipper',
                    component: ()=>import('@/components/Shipper/ShipperPage')
                },
                {
                    path: 'shipper/list',
                    name: 'shipper_list',
                    component: ()=>import('@/components/Shipper/ShipperListPage')
                },
                {
                    path: 'shipper/add',
                    name: 'shipper_add',
                    component: ()=>import('@/components/Shipper/ShipperAddPage')
                },
                {
                    path: 'freight',
                    name: 'freight',
                    component: ()=>import('@/components/Freight/FreightPage')
                },
                {
                    path: 'except_area',
                    name: 'except_area',
                    component: ()=>import('@/components/Freight/ExceptAreaPage')
                },
                {
                    path: 'except_area/add',
                    name: 'except_area_add',
                    component: ()=>import('@/components/Freight/ExceptAreaAddPage')
                },
                {
                    path: 'freight/add',
                    name: 'freight_add',
                    component: ()=>import('@/components/Freight/FreightAddPage')
                },
                {
                    path: 'notice',
                    name: 'notice',
                    component: ()=>import('@/components/Settings/NoticePage')
                },
                {
                    path: 'ad',
                    name: 'ad',
                    component: ()=>import('@/components/Ad/AdPage')
                },
                {
                    path: 'ad/add',
                    name: 'ad_add',
                    component: ()=>import('@/components/Ad/AdAddPage')
                },
                {
                    path: 'shopcart',
                    name: 'shopcart',
                    component: ()=>import('@/components/ShopCart/ShopCartPage')
                },
                {
                    path: 'keywords',
                    name: 'keywords',
                    component: ()=>import('@/components/Keywords/KeywordsPage')
                },
                {
                    path: 'keywords/add',
                    name: 'keywords_add',
                    component: ()=>import('@/components/Keywords/KeywordsAddPage')
                },
                {
                    path: 'goodsgalleryedit',
                    name: 'goodsgalleryedit',
                    component: ()=>import('@/components/GoodsGallery/GoodsGalleryEditPage')
                },
                {
                    path: 'admin',
                    name: 'admin',
                    component: ()=>import('@/components/Admin/AdminPage')
                },
                {
                    path: 'admin/add',
                    name: 'admin_add',
                    component: ()=>import('@/components/Admin/AdminAddPage')
                },
                {
                    path: 'settings/showset',
                    name: 'showset',
                    component: ()=>import('@/components/Showset/ShowSetPage')
                },
                {
                    path: 'qmhd',
                    name: 'qmhd_Questions',
                    component: ()=>import('@/components/Question/QMHD_Page')
                },
                {
                    path: 'qmhd/qmhd_add',
                    name: 'qmhd_addQuestion',
                    component: ()=>import('@/components/Question/QMHD_AddPage')
                },
                
                {
                    path: 'xxjgs',
                    name: 'xxjgs_Questions',
                    component: ()=>import('@/components/Question/XXJGS_Page')
                },
                {
                    path: 'xxjgs/xxjgs_add',
                    name: 'xxjgs_addQuestion',
                    component: ()=>import('@/components/Question/XXJGS_AddPage')
                },
            ]
        },
        {
            path: '/wap',
            name: 'wap',
            component: ()=>import('@/components/WapPage'),
            children: [
                {
                    path: '/',
                    name: 'goods',
                    component: ()=>import('@/components/Wap/GoodsPage')
                },
                {
                    path: 'order',
                    name: 'order',
                    component: ()=>import('@/components/Wap/OrderPage')
                },

            ]
        },
        {
            path:"/login",
            name:"login",
            component:()=>import("@/components/LoginPage")
        },
        {
            path: '*',
            redirect: '/dashboard/welcome'
        }
    ]
}

export default config;